<br />
<div class="container">
    <div *ngIf="!(auth.orgDoc$ | async)?.subscription.active ">
        <form #checkout="ngForm" (submit)="handleForm($event)" class="checkout">
            <h5 class="trial">
                Free Trial: {{(auth.orgDoc$ | async)?.userCount}} of 3 seats used.
            </h5>
            <h2>
                Start Monthly Subscription
            </h2>
           
            <h5>
                $1 per user / month
            </h5>
            <div class="form-row">
                <label for="card-info">Card Info</label>
                <div id="card-info" #cardElement></div>

                <div id="card-errors" role="alert" *ngIf="cardErrors">{{ cardErrors }}</div>
            </div>

            <button mat-flat-button color="accent">Start Subscription</button>
        </form>
        <div *ngIf="loading" class="notification is-info">Loading....</div>
        <pre *ngIf="confirmation" style="max-width: 500px;">
            {{ confirmation | json }}
        </pre>
        <!-- <h5>
            *First 3 users free. 4 to 100 users $1 per month, 101-200 users $0.70 per month, 200+ users $0.50 per month.
        </h5> -->
    </div>
    <div *ngIf="(auth.orgDoc$ | async)?.subscription.active ">
        <h2>Subscription Status: <span class="status">{{(auth.orgDoc$ | async)?.subscription?.status}}</span></h2>
        <h4>
            Next Bill Date: <span>{{(nextBillDate * 1000 | date) || 'Loading...'}}</span>
        </h4>
        <h4>
            Monthly Billing User Count:<span>{{meterUsage || 'Loading...'}}</span>
        </h4>
        <h4>
            Current User Count: <span>{{(auth.orgDoc$ | async)?.userCount}}</span>
        </h4>
        <br />
        <br />
        <button mat-stroked-button color="warn" (click)="cancelSubscription()">Cancel Subscription</button>
    </div>
</div>