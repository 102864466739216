import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from '../../services/auth.service';
import { NavService } from '../../services/nav.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent {
  constructor(public afAuth: AngularFireAuth, private auth: AuthService, private nav: NavService) { }

  async googleSignIn() {
    await this.auth.googleLogin();
    this.nav.navigate(['/questionnaire']);
  }

  signout() {
    this.auth.signOut();
  }
}
