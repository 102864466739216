<h3>
    Organization Settings
</h3>
<div class="container">
    <div class="toolbar">
        <div class="fill-space"></div>
        <a mat-button color="warn" (click)="save()" >Save</a>
    </div>
    <form [formGroup]="settingsForm">
        <mat-form-field class="actionItems">
            <mat-label for="name">Organization Name</mat-label><br>
            <input matInput class="lg" type="text" formControlName="name" placeholder="Org Name">
        </mat-form-field>

        <!-- <h3 class="is">is</h3>
        <mat-form-field class="status">
            <mat-label>Status</mat-label>
            <mat-select formControlName="status">
                <mat-option value="Open">Open</mat-option>
                <mat-option value="Closed">Closed</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-divider></mat-divider>
        <h4>Local Settings</h4>
        <br/> -->
        <br/>
        <br/>
        <div>
            <mat-form-field class="tz">
                <mat-label>Timezone</mat-label>
                <mat-select formControlName="tz">
                    <mat-option *ngFor="let tz of timezoneoptions" [value]="tz.utc[0]">
                        {{tz.text}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </form>
    <br />
</div>