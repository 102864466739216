<div *ngIf="!loading">
    <h3>
        {{title}}
    </h3>
    <p class="howto">
        Add, edit & modify questions.
    </p>
    <div>
        <div class="toolbar">
            <div class="fill-space"></div>
            <a mat-button color="warn" (click)="save()" >Save</a>
        </div>
        <mat-list>
            <div *ngFor="let question of questions; let i = index">
                <mat-list-item>
                    <table class="center">
                        <tr>
                            <th>
                                <h3 class="questionDesc">Question</h3>
                                <div class="question">
                                    {{i + 1}}. {{question.text}}
                                    <span>
                                        <button mat-icon-button class="editicon" (click)="editQuestion(question)"
                                            matTooltip="Edit question verbiage">
                                            <mat-icon>create</mat-icon>
                                        </button>
                                    </span>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th>
                                <section>
                                    <span>
                                        <h3 class="actionDesc">Actions</h3>
                                    </span>
                                    <div class="action">
                                        <h3 class="actionItems">If... </h3>
                                        <div class="actionItems">
                                            <button mat-flat-button color="warn">Yes</button>
                                        </div>
                                        <h3 class="actionItems">...then...</h3>
                                        <mat-form-field class="actionItems">
                                            <mat-label>Move to Group</mat-label>
                                            <mat-select required [(value)]="question.ifYesGroup">
                                                <mat-option value="Suspected">Suspected</mat-option>
                                                <mat-option value="Quarantined">Quarantined</mat-option>
                                                <mat-option value="">-Dont Move-</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="action">
                                        <h3 class="actionItems">If... </h3>
                                        <div class="actionItems">
                                            <button mat-flat-button color="accent">No</button>
                                        </div>
                                        <h3 class="actionItems">...then...</h3>
                                        <mat-form-field class="actionItems">
                                            <mat-label>Move to Group</mat-label>
                                            <mat-select required [(value)]="question.ifNoGroup">
                                                <mat-option value="Suspected">Suspected</mat-option>
                                                <mat-option value="Quarantined">Quarantined</mat-option>
                                                <mat-option value="">-Dont Move-</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </section>
                            </th>
                        </tr>
                        <tr>
                            <th>
                                <button mat-stroked-button color="warn" (click)="remove(i)">Delete
                                    Question</button>
                            </th>
                        </tr>
                    </table>
                </mat-list-item>
                <mat-divider></mat-divider>
            </div>
        </mat-list>
        <button mat-flat-button color="primary" class="addquestion" (click)="add()">Add Question</button>
    </div>
    <!-- <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Web Questions
                </mat-panel-title>
                <mat-panel-description>
                    Modify questions & workflow.
                </mat-panel-description>
            </mat-expansion-panel-header>
           
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    SMS Questions
                </mat-panel-title>
                <mat-panel-description>
                    Modify questions & workflow.
                </mat-panel-description>
            </mat-expansion-panel-header>
            <br />
            <h3 class="questionDesc">1. SMS Type</h3>
            <mat-form-field class="smsType">
                <mat-label>SMS Type</mat-label>
                <mat-select [(value)]="smsQuestion.type">
                    <mat-option value="message" matTooltipPosition="after"
                        matTooltip="A simple sms message with no link or reply functionality.">
                        Message Only
                    </mat-option>
                    <mat-option value="link" matTooltipPosition="after"
                        matTooltip="SMS message will also include a link to User daily web form.">Message
                        w/ Web Form Link
                    </mat-option>
                    <mat-option value="reply" matTooltipPosition="after"
                        matTooltip="Send a Yes No question and allow users to reply by sms.">Message
                        w/ Reply (Yes,No)
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <h3 class="questionDesc">2. Sms Message</h3>
            <div class="chat">
                <div class="mine messages">
                    <div class="message last">
                        {{smsQuestion?.question?.text}}
                        <p *ngIf="smsQuestion.type === 'link'">Tap Here - https://covidfreeworkforce.com/q/A1Bo9</p>
                        <p *ngIf="smsQuestion.type === 'reply'">Reply: Yes No</p>
                    </div>
                </div>
                <button mat-icon-button class="editicon" (click)="editQuestion(smsQuestion.question)"
                    matTooltip="Edit question verbiage">
                    <mat-icon>create</mat-icon>
                </button>
            </div>
            <br />
            <br />
            <section *ngIf="smsQuestion.type === 'reply'">
                <span>
                    <h3 class="actionDesc">3. Actions</h3>
                </span>
                <br />
                <div class="action">
                    <h3 class="actionItems">If... </h3>
                    <div class="actionItems">
                        <button mat-flat-button color="warn">Yes</button>
                    </div>
                    <h3 class="actionItems">...then...</h3>
                    <mat-form-field class="actionItems">
                        <mat-label>Move to Group</mat-label>
                        <mat-select required [(value)]="smsQuestion.question.ifYesGroup">
                            <mat-option value="Suspected">Suspected</mat-option>
                            <mat-option value="Quarantined">Quarantined</mat-option>
                            <mat-option value="">-Dont Move-</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="action">
                    <h3 class="actionItems">If... </h3>
                    <div class="actionItems">
                        <button mat-flat-button color="accent">No</button>
                    </div>
                    <h3 class="actionItems">...then...</h3>
                    <mat-form-field class="actionItems">
                        <mat-label>Move to Group</mat-label>
                        <mat-select required [(value)]="smsQuestion.question.ifNoGroup">
                            <mat-option value="Suspected">Suspected</mat-option>
                            <mat-option value="Quarantined">Quarantined</mat-option>
                            <mat-option value="">-Dont Move-</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </section>
            <br/>
            <button mat-stroked-button color="primary" style="float:right;" (click)="save()">Save</button>
            <br/>
        </mat-expansion-panel>
    </mat-accordion> -->
</div>