import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-flagged-modal',
  templateUrl: './flagged-modal.component.html',
  styleUrls: ['./flagged-modal.component.scss']
})
export class FlaggedModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
