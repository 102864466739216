<main>
  <div *ngIf="!(afAuth.authState | async)">
    <!-- <h1>Login</h1> -->
    <br/>
    <br/>
    <br/>
    <br/>
    <!-- <button mat-raised-button appGoogleSignin>  -->
      <!-- <button mat-raised-button (click)="googleSignIn()"> 
      <img src="/assets/google-logo.svg" /> Login with Google
    </button>

    <h5>OR</h5> -->

    <app-email-login></app-email-login>
  </div>

  <div *ngIf="afAuth.authState | async as user" class="logout">
    <p>
      Logged in as <strong>{{ user.email }}</strong>
    </p>

    <button mat-stroked-button (click)="signout()">Logout</button>
  </div>
</main>
