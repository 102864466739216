import { Component, OnInit, OnDestroy, ApplicationRef, NgZone } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { NgxSpinnerService } from "ngx-spinner";
import { tap, map } from 'rxjs/operators';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {
  orgSub;
  questions;
  loading: boolean = true;
  mySpinner;
  org;

  constructor(public auth: AuthService, public ngZone: NgZone, public spinner: NgxSpinnerService) {
    this.orgSub = this.auth.orgDoc$.subscribe((org: any) => {
      ngZone.run(() => {
        this.org = org;
        //this.questions = org.questions[0].questions;
      });
    });
  }

  ngOnInit() {

  }

  ngOnDestroy(){
    this.orgSub.unsubscribe();
  }

}
