import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-question-edit',
  templateUrl: './question-edit.component.html',
  styleUrls: ['./question-edit.component.scss']
})
export class QuestionEditComponent implements OnInit {
  questionForm: FormGroup;

  constructor(private fb: FormBuilder
    , public dialogRef: MatDialogRef<QuestionEditComponent>
    , @Inject(MAT_DIALOG_DATA) public txt: string) {
    this.questionForm = this.fb.group({
      question: [txt, [Validators.required]]
    });
  }

  ngOnInit(): void {
  }


  close() {
    this.dialogRef.close();
  }

  get question() {
    return this.questionForm.get("question").value;
  }

}
