import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthGuard, AuthGuardIsAdmin } from './user/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'login', loadChildren: () => import('./user/user.module').then(m => m.UserModule) },
  //{ path: 'customers', loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule) },
  { path: 'dashboard', canActivate: [AuthGuardIsAdmin], loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'signup', loadChildren: () => import('./signup/setup.module').then(m => m.SetupModule) },
  { path: 'users', canActivate: [AuthGuardIsAdmin], loadChildren: () => import('./users/users.module').then(m => m.UsersModule) },
  { path: 'settings', canActivate: [AuthGuardIsAdmin], loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule) },
  { path: 'questionnaire', canActivate: [AuthGuard], loadChildren: () => import('./questionair/questionair.module').then(m => m.QuestionairModule) },
  { path: 'history', canActivate: [AuthGuard], loadChildren: () => import('./history/history.module').then(m => m.HistoryModule) },
  { path: 'support', loadChildren: () => import('./support/support.module').then(m => m.SupportModule) },
  { path: 'pricing', loadChildren: () => import('./pricing/pricing.module').then(m => m.PricingModule) },
  { path: 'home', canActivate: [AuthGuard], loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'dailyparticipation', canActivate: [AuthGuardIsAdmin], loadChildren: () => import('./dailyparticipation/dailyparticipation.module').then(m => m.DailyparticipationModule) },
  { path: 'announcements', canActivate: [AuthGuard], loadChildren: () => import('./announcements/announcements.module').then(m => m.AnnouncementsModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
