import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { SnackService } from '../services/snack.service';
import { NavService } from '../services/nav.service';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private snack: SnackService,
    private nav: NavService
  ) {

  }

  canActivate(): Observable<boolean> {
    return this.auth.user$.pipe(map(user => {
      if (!user){
        this.nav.navigate(['login']);
        return false;
      }
      return true;
    }));
  }
  //   return new Promise((resolve, reject) => {
  //     this.auth.user$.toPromise()
  //       .then(user => {
  //         console.log(user);
  //         return resolve(false);
  //       }, err => {
  //         console.log(err);
  //         return resolve(true);
  //       }).catch(()=>{
  //         console.log('aaa');
  //         return resolve(false);
  //       });
  //   })
  // }
}



@Injectable({
  providedIn: 'root'
})
export class AuthGuardIsAdmin implements CanActivate {
  constructor(
    private auth: AuthService,
    private nav: NavService
  ) { }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const isAdmin = await this.auth.isAdmin;
    if (!isAdmin)
      this.nav.navigate(['signup']);
    return isAdmin;
  }
}
