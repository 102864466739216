<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="false">
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list>
      <!-- <a mat-list-item routerLink="/login" (click)="drawer.close()">Login</a>
      <a mat-list-item routerLink="/" (click)="drawer.close()">Dashboard</a>
      <a mat-list-item routerLink="/kanban" (click)="drawer.close()">Users</a>
      <a mat-list-item routerLink="/customers" (click)="drawer.close()">Questions</a> -->

      <a mat-list-item (click)="navigate(['/home'])" *ngIf="(auth.orgId)">Home</a>
      <a mat-list-item (click)="navigate(['/questionnaire'])" *ngIf="(auth.orgId)">Submit Covid Check</a>
      <a mat-list-item (click)="navigate(['/history'])" *ngIf="(auth.orgId)">My History</a>
      <a mat-list-item (click)="navigate(['/signup'])" *ngIf="auth.orgId == '' || auth.orgId == null ">Try Free</a>
      <a mat-list-item *ngIf="auth.isAdmin" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"><img
          src="/assets/key.png" class="key">Admin</a>
      <!-- <a mat-list-item (click)="navigate(['/support'])">Support</a> -->
      <a mat-list-item routerLink="/login" *ngIf="!(afAuth.authState | async)">Login</a>
      <a mat-list-item routerLink="/login" *ngIf="(afAuth.authState | async)">Profile</a>
      <a mat-list-item routerLink="/login" *ngIf="(afAuth.authState | async)">Logout</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar>
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">

        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span class="logo" routerLink="/">
        <div>
          <b>CovidFreeWorkforce</b>
        </div>
        <div class="orgName">
          {{auth?.orgDoc?.settings?.orgSettings?.name || ''}}
        </div>
      </span>
      <span class="fill-space"></span>
      <div *ngIf="!(isHandset$ | async)">
        <a mat-button (click)="navigate(['/home'])" *ngIf="(auth.orgId)">Home</a>
        <a mat-button (click)="navigate(['/questionnaire'])" *ngIf="(auth.orgId)">Submit Covid Check</a>
        <a mat-button (click)="navigate(['/history'])" *ngIf="(auth.orgId)">My History</a>
        <a mat-button (click)="navigate(['/signup'])" *ngIf="auth.orgId == '' || auth.orgId == null ">Try Free</a>
        <a mat-button *ngIf="auth.isAdmin" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"><img
            src="/assets/key.png" class="key">Admin</a>
        <!-- <a mat-button (click)="navigate(['/support'])">Support</a> -->
        <a mat-button routerLink="/login" *ngIf="!(afAuth.authState | async)">Login</a>
        <a mat-button routerLink="/login" *ngIf="afAuth.authState | async as user">
          <img class="avatar" [src]="user.photoURL || 'assets/default-user.png'">
          Profile
        </a>
      </div>
      <mat-menu #menu="matMenu">
        <a mat-menu-item (click)="navigate(['/dashboard'])">
          <span>Dashboard</span>
        </a>
        <a mat-menu-item (click)="navigate(['/announcements'])">
          <span>Announcements</span>
        </a>
        <a mat-menu-item (click)="navigate(['/users'])">
         <!-- <mat-icon aria-hidden="false" aria-label="Example home icon">supervisor_account</mat-icon> -->
          <span>Users</span>
        </a>
        <!-- <a mat-menu-item (click)="navigate(['/customerwellness'])">
          <span>Customer Wellness</span>
        </a> -->
        <a mat-menu-item (click)="navigate(['/settings'])">
          <!-- <mat-icon aria-hidden="false" aria-label="Example home icon">settings</mat-icon> -->
          <span>Settings</span>
        </a>

      </mat-menu>
    </mat-toolbar>

    <ng-content></ng-content>

  </mat-sidenav-content>
</mat-sidenav-container>