
import { Component, OnInit, ViewChild, ElementRef, Input, AfterViewInit, OnDestroy } from '@angular/core';

import { AngularFireFunctions } from '@angular/fire/functions';
import { AuthService } from 'src/app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
declare var Stripe: any;


@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit, AfterViewInit, OnDestroy {
  orgDoc$;
  constructor(public auth: AuthService
    , private spinner:NgxSpinnerService
    , private functions: AngularFireFunctions) {
    this.orgDoc$ = this.auth.orgDoc$.subscribe((org: any) => {
      if (!org || org === undefined)
        return;
      if (org.subscription && org.subscription.active) {
        this.getSubscription();
        this.getSubscriptionUsage();
      }
    })
  }

  @Input() amount: number;
  @Input() description: string;
  @ViewChild('cardElement') cardElement: ElementRef;

  stripe; // : stripe.Stripe;
  card;
  cardErrors;

  loading = false;
  confirmation;
  nextBillDate;
  meterUsage;

  ngOnInit() {

  }

  ngOnDestroy() {
    if (this.orgDoc$)
      this.orgDoc$.unsubscribe();
  }

  ngAfterViewInit() {
    this.stripe = Stripe('pk_live_P3h4XgV4jvmIHPp66JNtENs200JuedCXuv');
    const elements = this.stripe.elements();

    this.card = elements.create('card');
    this.card.mount(this.cardElement.nativeElement);

    this.card.addEventListener('change', ({ error }) => {
      this.cardErrors = error && error.message;
    });
  }

  async handleForm(e) {
    e.preventDefault();
    const { source, error } = await this.stripe.createSource(this.card);

    if (error) {
      // Inform the customer that there was an error.
      const cardErrors = error.message;
    } else {
      // Send the token to your server.
      this.loading = true;
      const user = await this.auth.user;
      this.spinner.show();
      const fun = this.functions.httpsCallable('stripeCreateSubscription');
      this.confirmation = await fun({ source: source, planId: 'plan-a' }).toPromise();
      this.spinner.hide();
      this.loading = false;
      this.getSubscription();
      this.getSubscriptionUsage();

    }
  }

  async cancelSubscription() {
    this.spinner.show();
    const fun = this.functions.httpsCallable('stripeCancelSubscription');
    const rtn = await fun({ subId: this.auth.orgDoc.subscription.id }).toPromise();
    this.spinner.hide();
  }

  async getSubscription() {
    const fun = this.functions.httpsCallable('stripeGetSubscriptions');
    const rtn = await fun({}).toPromise();
    const inv = rtn.data[0];
    if (inv)
      this.nextBillDate = inv.current_period_end;

  }

  async getSubscriptionUsage() {
    const fun = this.functions.httpsCallable('stripeGetSubscriptionUsage');
    const rtn = await fun({}).toPromise();
    const inv = rtn.data[0];
    this.meterUsage = inv.total_usage;

  }

}