<h1>Edit Question</h1>
<form [formGroup]="questionForm" class="questionForm">
    <div>
        <mat-form-field [color]="question.valid && 'accent'" class="questionForm">
            <textarea matInput rows="4" formControlName="question" type="text" placeholder="Question Verbiage"
                autocomplete="off"></textarea>
            <mat-error *ngIf="question.invalid">
                You must enter a question.
            </mat-error>
        </mat-form-field>
    </div>
    <div class="floatright">
        <button mat-flat-button color="basic" [mat-dialog-close]>
            Cancel
        </button>
        <button mat-flat-button color="accent" [mat-dialog-close]="question" [disabled]="questionForm.invalid">
            Save
        </button>
    </div>
</form>