import { Injectable, ApplicationRef, NgZone } from '@angular/core';

import * as firebase from 'firebase/app';
import { auth } from 'firebase/app';
import { analytics } from 'firebase/app';
import { user } from 'rxfire/auth';
import { tap, switchMap, take, map } from 'rxjs/operators';
import { docData } from 'rxfire/firestore';
import { of, Observable } from 'rxjs';
import 'firebase/auth'
import { Router } from '@angular/router';
import { NavService } from './nav.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  authClient = firebase.auth();
  //analytics = firebase.analytics();

  user$: Observable<any>;
  userDoc$: Observable<any>;
  orgDoc$: Observable<any>;
  newOrgDoc$: Observable<any>;
  userProducts$: Observable<any>;

  user;
  userDoc;
  orgDoc;


  private _isAdmin: boolean = false;

  constructor(private app: ApplicationRef, private route: Router, private navService: NavService, private ngZone: NgZone) {
    // Why service subsciptions? Maintain state between route changes with change detection.
    this.setupSubs();
  }

  setupSubs() {
    this.user$ = user(this.authClient)
      .pipe(tap(async u => {
        this.user = u;
        this._isAdmin = false;
        if (u) {
          const token = await this.authClient.currentUser.getIdTokenResult();
          this._isAdmin = token.claims.orgAdmin || false;
        }
        this.app.tick();
      }));

    this.userDoc$ = this.getUserDoc$('users').pipe(tap(u => {
      this.userDoc = u;
      this.app.tick();
      if (u) {
      }
    }));

    this.orgDoc$ = this.getOrgDoc$().pipe(tap(async u => {
      this.orgDoc = u;
      this.app.tick();
      if (u) {
      }
    }));

    this.user$.subscribe();
    this.userDoc$.subscribe();
    this.orgDoc$.subscribe();
  }

  destorySubs() {
  }

  redirectUser() {

  }

  getUserDoc$(col) {
    return user(this.authClient).pipe(

      switchMap(u => {
        return u ? docData(firebase.firestore().doc(`${col}/${(u as any).uid}`)).pipe() : of(null);
      })
    );
  }

  getOrgDoc$() {
    return this.userDoc$.pipe(
      switchMap((u: any) => {
        if (!u || !u.orgId)
          return of(null);
        //return u && u.orgId ? docData(firebase.firestore().collection('orgs').doc(`orgs/${(u as any).orgId}`)).pipe() : of(null);
        return u && u.orgId ? docData(firebase.firestore().doc(`orgs/${(u as any).orgId}`)).pipe() : of(null);
      })
    );
  }

  signOut() {

    this.authClient.signOut();
  }

  async googleLogin() {
    const credential = this.authClient.signInWithPopup(new auth.GoogleAuthProvider());
    return this.loginHandler(credential);
  }

  async appleLogin() {
    const provider = new firebase.auth.OAuthProvider('apple.com');
    const credential = this.authClient.signInWithPopup(provider);
    return this.loginHandler(credential);
  }

  get userId() {
    return this.user ? this.user.uid : null;
  }

  get orgId() {
    return this.userDoc ? this.userDoc.orgId : null;
  }

  get isAdmin() {
    if (!user)
      return false;
    return this._isAdmin
  }

  get userCurrentGroup() {
    if (!this.orgDoc || !this.user || !this.orgDoc.userGroups)
      return '';
    if (this.orgDoc.userGroups.quarantined.indexOf(this.userId) !== -1) {
      return 'quarantined'
    }

    if (this.orgDoc.userGroups.suspected.indexOf(this.userId) !== -1) {
      return 'suspected'
    }
    return '';
  }

  async emailSignup(email: string, password: string) {
    const credential = this.authClient.createUserWithEmailAndPassword(email, password);
    return this.loginHandler(credential);
  }

  async emailLogin(email: string, password: string) {
    const credential = this.authClient.signInWithEmailAndPassword(email, password);
    return this.loginHandler(credential);
  }

  async resetPassword(email: string) {
    return this.authClient.sendPasswordResetEmail(email);
  }

  async loginHandler(promise) {
    let res, serverError;
    try {
      res = await promise;
      //this.analytics.logEvent('login', {});
    } catch (err) {
      serverError = err.message;
      console.error(err);
    }

    return { res, serverError };
  }
}
