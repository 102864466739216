import { Component, NgZone, ApplicationRef } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { NavService } from '../../services/nav.service';
@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent {
  orgName: string = null;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Handset])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver
    , public afAuth: AngularFireAuth
    , public auth: AuthService
    , public navService: NavService
    , private app: ApplicationRef) {

    // this.auth.orgDoc$.subscribe(x => {
    //   this.orgName = x.name;
    //   this.app.tick();
    // });
  }

  public navigate(commands: any[]): void {
   this.navService.navigate(commands);
  }
}
