import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsRoutingModule } from './settings-routing.module';
import { SettingsQuestionsComponent } from './questions/questions.component';
import { SettingsComponent } from './settings.component';
import { QuestionEditComponent } from './questions/question-edit/question-edit.component';
import { QuestionDeleteComponent } from './questions/question-delete/question-delete.component';
import { GroupsNotificationComponent } from './groups-notification/groups-notification.component';
import { OrgSettingsComponent } from './org-settings/org-settings.component';
import { CustomerquestionsComponent } from './customerquestions/customerquestions.component'
import { BillingComponent } from './billing/billing.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [SettingsComponent, SettingsQuestionsComponent, QuestionEditComponent, QuestionDeleteComponent, GroupsNotificationComponent, OrgSettingsComponent, CustomerquestionsComponent, BillingComponent],
  imports: [
    CommonModule,
    SharedModule,
    SettingsRoutingModule
  ],
  exports: [],
  entryComponents: [QuestionEditComponent]
})
export class SettingsModule { }
