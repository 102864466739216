import { Component, OnInit, Input, NgZone } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialog } from '@angular/material/dialog';
import { QuestionEditComponent } from './question-edit/question-edit.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from 'src/app/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QuestionDeleteComponent } from './question-delete/question-delete.component';
import * as firebase from 'firebase/app';


@Component({
  selector: 'settings-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class SettingsQuestionsComponent implements OnInit {
  @Input() title: string = "Modify Questions"
  @Input()
  set org(org) {
    if (org === undefined)
      return;
    this._org = org;
    this.questions = org.settings.UserQuestions.formQuestions[0].questions;
    this.smsQuestion = org.settings.UserQuestions.smsQuestions[0];
    this.loading = false;
  }

  _org;
  questions: any = [];
  smsQuestion: any = [];
  loading: boolean = true;

  constructor(public dialog: MatDialog
    , private afs: AngularFirestore
    , public ngZone: NgZone
    , private snack: MatSnackBar
    , private auth: AuthService) {

  }

  ngOnInit(): void {
  }

  // async doMagic() {
  //   const orgId = this.auth.orgId;
  //   await this.afs.collection('orgs').doc(orgId).set({
  //     settings: {
  //       questions: this._org.questions,
  //       notifications: {}
  //     }
  //   }, { merge: true });
  // }

  async save() {
    this._org.settings.UserQuestions.formQuestions[0].questions = this.questions;
    var updateOrgSettings = firebase.functions().httpsCallable('updateOrgSettings');
    const rtn: any = await updateOrgSettings(this._org.settings);
    if (rtn.error) {
      return this.snack.open('Error Saving Settings', 'Error', { duration: 2000 });
    } else {
      return this.snack.open('Saving Settings...', 'Successfull', { duration: 2000 });
    }


    // const orgId = this.auth.orgId;
    // if (!orgId)
    //   return;
    // console.log('saved');

    // await this.afs.collection('orgs').doc(orgId).set({
    //   questions: [{ name: 'Wellness Check', questions: this.questions }]
    // }, { merge: true });
    // const sb = this.snack.open('Settings', 'Saved', { duration: 2000 });
  }

  editQuestion(question) {
    const dialogRef = this.dialog.open(QuestionEditComponent, {
      height: '300px',
      width: '550px',
      data: question.text
    });
    dialogRef.afterClosed().subscribe(result => {
      if (!result)
        return;
      question.text = result;
      //this.save();
    });
  }

  remove(i) {
    const dialogRef = this.dialog.open(QuestionDeleteComponent, {
      height: '250px',
      width: '400px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (!result)
        return;
      this.questions.splice(i, 1);
      //this.save();
    });

  }

  add() {
    const question: any = {
      "type": "yesno",
      "text": "Enter question here.",
      "value": "",
      "ifYesGroup": "",
      "ifNoGroup": ""
    };
    this.questions.push(question);
  }


}
