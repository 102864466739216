import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { MatSnackBar } from '@angular/material/snack-bar';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'settings-groups-notification',
  templateUrl: './groups-notification.component.html',
  styleUrls: ['./groups-notification.component.scss']
})
export class GroupsNotificationComponent implements OnInit {
  @Input()
  set org(org) {
    if (org === undefined)
      return;
    this._org = org;
    this.settings = org.settings.notifications || {};
    if (this.settings) {
      const { dailyReminders, dailySMSReminderTime, dailyEmailReminderTime, distroGroup, notifyOnQuarantined, notifyOnSuspected } = this.settings;
      this.settingsForm.patchValue({
        dailyReminders, dailySMSReminderTime, dailyEmailReminderTime, notifyOnQuarantined, notifyOnSuspected
      });
      this.emailAddresses = distroGroup;
    }
    //this.dailyReminders = this.settings.dailyReminders;
  }

  _org;
  settings: any = {};
  openSnackbars;
  emailForm: FormGroup;
  settingsForm: FormGroup;
  emailAddresses: any = ['raminnoodle@gmail.com'];
  constructor(public auth: AuthService
    , private afs: AngularFirestore
    , private snack: MatSnackBar
    , private fb: FormBuilder) {
    this.emailForm = this.fb.group({
      email: ['',
        [Validators.required, Validators.email]
      ]
    });

    this.settingsForm = this.fb.group({
      dailyReminders: [''],
      dailySMSReminderTime: [''],
      dailyEmailReminderTime: [''],
      notifyOnSuspected: [''],
      notifyOnQuarantined: [''],
      flagMessage: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
  }

  add() {
    this.emailAddresses.indexOf(this.email.value.toLowerCase()) === -1 ? this.emailAddresses.push(this.email.value.toLowerCase()) : null;
    this.save();
  }

  remove(i) {
    this.emailAddresses.splice(i, 1);
    this.save();
  }

  get dailyReminders() {
    return this.settingsForm.get("dailyReminders");
  }

  get dailySMSReminderTime() {
    return this.settingsForm.get("dailySMSReminderTime");
  }

  get dailyEmailReminderTime() {
    return this.settingsForm.get("dailyEmailReminderTime");
  }

  get flagMessage() {
    return this.settingsForm.get("flagMessage");
  }


  get notifyOnSuspected() {
    return this.settingsForm.get("notifyOnSuspected");
  }

  get notifyOnQuarantined() {
    return this.settingsForm.get("notifyOnQuarantined");
  }

  get email() {
    return this.emailForm.get("email");
  }

  async save() {
    let newSettings = {
      dailyReminders: this.dailyReminders.value,
      dailyEmailReminderTime: this.dailyEmailReminderTime.value,
      dailySMSReminderTime: this.dailySMSReminderTime.value,
      notifyOnSuspected: this.notifyOnSuspected.value,
      notifyOnQuarantined: this.notifyOnQuarantined.value,
      distroGroup: this.emailAddresses || []
    };

    this._org.settings.notifications = newSettings;
    var updateOrgSettings = firebase.functions().httpsCallable('updateOrgSettings');
    const rtn: any = await updateOrgSettings(this._org.settings);
    if (rtn.error) {
      return this.showSnackbar('Error Saving Settings');
    } else {
      return this.showSnackbar('Settings Saved');
    }
  }

  private showSnackbar(message: string) {
    this.openSnackbars++;
    return this.snack.open(message, 'close', {
      duration: 1000
    }).afterDismissed().pipe(tap(() => this.openSnackbars--));
  }

}
