import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-question-delete',
  templateUrl: './question-delete.component.html',
  styleUrls: ['./question-delete.component.scss']
})
export class QuestionDeleteComponent implements OnInit {

  constructor(private fb: FormBuilder
    , public dialogRef: MatDialogRef<QuestionDeleteComponent>
    , @Inject(MAT_DIALOG_DATA) public txt: string) {

  }

  ngOnInit(): void {
  }

}
