import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customerquestions',
  templateUrl: './customerquestions.component.html',
  styleUrls: ['./customerquestions.component.scss']
})
export class CustomerquestionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
