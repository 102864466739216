<h3>
    Noticiation Settings
</h3>
<div class="container">
    <div class="toolbar">
        <div class="fill-space"></div>
        <a mat-button color="warn" (click)="save()">Save</a>
    </div>
    <br />
    <form [formGroup]="settingsForm">
        <!-- <mat-checkbox formControlName="dailyReminders">Daily User reminders.</mat-checkbox>
        <div *ngIf="dailyReminders.value === true">Email users who have not completed a wellness check by... &nbsp;
            <mat-form-field>
                <mat-label>Time</mat-label>
                <mat-select formControlName="dailyEmailReminderTime">
                    <mat-option value="8">
                        8am
                    </mat-option>
                    <mat-option value="9">
                        9am
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="dailyReminders.value === true">SMS users who have not completed a wellness check by... &nbsp;
            <mat-form-field>
                <mat-label>Time</mat-label>
                <mat-select formControlName="dailySMSReminderTime">
                    <mat-option value="8">
                        8am
                    </mat-option>
                    <mat-option value="9">
                        9am
                    </mat-option>
                    <mat-option value="10">
                        10am
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div> -->
        <div>
            <mat-checkbox formControlName="notifyOnSuspected">Send notifications when users move into the
            </mat-checkbox><span class="suspected">Suspected</span> group.
        </div>
        <div>
            <mat-checkbox formControlName="notifyOnQuarantined">Send notifications when users move
                into the </mat-checkbox><span class="quarantined">Quarantined</span> group.
        </div>
    </form>
    <br />
    <app-flagged-modal></app-flagged-modal>
    <!-- <div>
        <mat-form-field class="flagMessage" [color]="flagMessage.valid && 'accent'" class="emailInput">
            <textarea matInput rows="4" formControlName="flagMessage" type="text" placeholder="Contact John @ 123-456-7890"
                autocomplete="off"></textarea>
        </mat-form-field>
    </div> -->
    <br />
    <mat-divider></mat-divider>

    <h3>Notification Distribution Group</h3>
    <form [formGroup]="emailForm">
        <mat-form-field class="emailInput" [color]="email.valid && 'accent'">
            <input matInput formControlName="email" type="email" placeholder="Email" autocomplete="off" />
            <mat-error *ngIf="email.invalid && email.dirty">
                You must enter a valid email address
            </mat-error>
        </mat-form-field>
        &nbsp;&nbsp;&nbsp;
        <button mat-stroked-button color="primary" [disabled]="emailForm.invalid" (click)="add()">Add</button>
    </form>
    <br />
    <br />
    <p *ngIf="emailAddresses.length === 0">
        No email addresses.
    </p>
    <mat-chip-list #chipList aria-label="Email Addresses">

        <mat-chip color="accent" selectable="false" *ngFor="let address of emailAddresses; let i = index">
            {{address}}
            <mat-icon matChipRemove (click)="remove(i)">cancel</mat-icon>
        </mat-chip>
    </mat-chip-list>
</div>