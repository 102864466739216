import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { MatSnackBar } from '@angular/material/snack-bar';
import { tap } from 'rxjs/operators';
import * as timezonedata from './timezones.json';

@Component({
  selector: 'app-org-settings',
  templateUrl: './org-settings.component.html',
  styleUrls: ['./org-settings.component.scss']
})
export class OrgSettingsComponent implements OnInit {
  @Input()
  set org(org) {
    if (org === undefined)
      return;
    this._org = org;
    this.settings = org.settings.orgSettings || {};
    if (this.settings) {
      const { name, status, tz } = this.settings;
      this.settingsForm.patchValue({
        name, status, tz
      });
    }
  }

  _org;
  settings: any = {};
  openSnackbars;
  settingsForm: FormGroup;
  timezoneoptions:  any  = (timezonedata  as  any).default;

  constructor(public auth: AuthService
    , private afs: AngularFirestore
    , private snack: MatSnackBar
    , private fb: FormBuilder) {
    this.settingsForm = this.fb.group({
      name: [''],
      status: [''],
      tz: ['']
    });
   
  }

  ngOnInit(): void {
  }


  get name() {
    return this.settingsForm.get("name");
  }

  get status() {
    return this.settingsForm.get("status");
  }

  get tz() {
    return this.settingsForm.get("tz");
  }


  async save() {
    let newSettings = {
      name: this.name.value,
      status: this.status.value || []
    };
    this._org.settings.orgSettings = newSettings;
    var updateOrgSettings = firebase.functions().httpsCallable('updateOrgSettings');

    try {
      const rtn: any = await updateOrgSettings(this._org.settings);
      if (rtn.error) {
        return this.showSnackbar('Error Saving Settings');
      } else {
        return this.showSnackbar('Settings Saved');
      }
    } catch (err) {
      console.log(err);
      return;
    }

  }

  private showSnackbar(message: string) {
    this.openSnackbars++;
    return this.snack.open(message, 'close', {
      duration: 1000
    }).afterDismissed().pipe(tap(() => this.openSnackbars--));
  }

}
