<h1>Settings</h1>

<div class="container">
    <mat-tab-group>
        <mat-tab label="Organization">
            <!-- ADD CHANGE SUSPECTED OR QUARNTEENED MESSAGE AND DISPLAY IT IF MET WHEN TEST TAKEN -->
            <app-org-settings [org]="org"></app-org-settings>
        </mat-tab>
        <mat-tab label="Covid Check Questions">
            <settings-questions [org]="org" title="Modify User Questions"></settings-questions>
        </mat-tab>
        <!-- <mat-tab label="Customer Wellness">
            <settings-questions [org]="org" title="Modify Customer Questions"></settings-questions>
        </mat-tab> -->
        <mat-tab label="Notification">
            <settings-groups-notification [org]="org"></settings-groups-notification>
        </mat-tab>
        <mat-tab label="Billing"> 
            <app-billing></app-billing>
         </mat-tab>
    </mat-tab-group>
</div>

 <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "square-jelly-box" [fullScreen] = "false"><p style="color: white" > Loading... </p></ngx-spinner>