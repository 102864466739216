<div>
    <span class="material-icons">
        warning
    </span>
    <h2>
        Contact Supervisor
    </h2>
    <h4>
        Please call 123-456-7890.
    </h4>
</div>